@import (reference) './app.less';

// Common print styles

@media print {
  @page {
    margin: 1in;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: transparent;

    .ra-header,
    .ra-base-header,
    .ra-footer,
    .ra-notifications-list,
    .ra-message,
    .ra-overlay-bar,
    .@{chat-cls} {
      display: none;
    }

    .ra-layout-content .ra-section.ra-section-color-gray {
      background-color: transparent;
    }
  }
}
