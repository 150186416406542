@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@error-cls: ~'@{ra-prefix}-error';
@error-main-cls: ~'@{error-cls}-main';
@error-logo-cls: ~'@{error-cls}-logo';

:export {
  errorCls: @error-cls;
  errorMainCls: @error-main-cls;
  errorLogoCls: @error-logo-cls;
}

.@{error-cls} {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: @ra-color-white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .@{error-main-cls} {
    margin: 15px;
    max-width: 500px;

    .@{error-logo-cls} {
      text-align: center;

      .ra-logo {
        margin: 0 auto 25px;
        width: 180px
      }
    }
  }
}
