@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@hidden-overflow-cls: ~'@{ra-prefix}-hidden-overflow';
@chat-hidden-cls: ~'@{ra-prefix}-chat-hidden';
@chat-cls: ~'intercom-lightweight-app';
@disable-scroll-cls: ~'@{ra-prefix}-disable-scroll';

:export {
  hiddenOverflowCls: @hidden-overflow-cls;
  chatHiddenCls: @chat-hidden-cls;
  disableScrollCls: @disable-scroll-cls;
}

.@{disable-scroll-cls} {
  overflow-y: hidden;
}

// Core application classes
body {
  &.@{chat-hidden-cls} {
    .@{chat-cls} {
      display: none;
    }
  }

  > .@{chat-cls} {
    z-index: @ra-zindex-chat;
  }

  #application {
    overflow: hidden;
  }

  &.disable-scroll {
    overflow: hidden;

    #application {
      height: 100%;
    }
  }

  &.@{hidden-overflow-cls} {
    overflow: hidden;
  }

  // stylelint-disable-next-line selector-id-pattern
  #credential_picker_container {
    top: 60px;
    right: 12px;

    @media only screen and (min-width: @ra-screen-lg) {
      top: 70px;
    }

    @media only screen and (min-width: @ra-screen-md) {
      right: 10px;
    }
  }
}
